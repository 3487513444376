import React from "react";
import Link from "gatsby-link";

import {
  Jumbotron,
  Grid,
  Row,
  Col,
  Image,
  Button,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  TabContainer,
  TabContent,
  TabPane,
  Container
} from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { JobHeader } from "../../components/job-header.js";

const SuperdrugMobilePage = () => (
  <Layout>

    <JobHeader
      image="/img/superdrug-mobile-bg.jpg"
      title="Superdrug Mobile"
      subtitle="Creating a mobile brand from scratch"
      text="Brand Guidelines / POS Design / Packaging Design / Animation"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            My reponsibilities on this project were hugely varied. I met with
            Superdrug and Three Mobile's Beta branding agency to help develop
            the brand. From that I wrote the brand guidelines, produced the SIM
            design, packaging design and the POS design. I now support Superdrug
            Mobile day to day with online activity, producing animations and on
            going POS designs.{" "}
          </em>
        </p>
      </Col>
    </Row>

    <Container>
      <Row>
        <Col>
          <Tabs onSelect={(index, label) => console.log(label + " selected")}>
            <Tab label="Brand Guidelines">
              <Row className="show-grid">
                <Col xs={12} sm={12} md={12}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-cover.jpg" />
                </Col>
              </Row>

              <Row className="show-grid side-padding ptb60">
                <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
                  <h2>Brand Guidelines</h2>
                  <p>
                    A big part of this project was producing the brand
                    guidelines. I worked with Superdrug and their external
                    agency to come up with the look and feel of the brand, the
                    colour pallete, the typography and put this all in to a
                    digital booklet that I then presented to Superdrug.
                  </p>
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12} md={6}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-logo.jpg" />
                </Col>
                <Col xs={12} md={6}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-star.jpg" />
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-imagery-to-use.jpg" />
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12} md={6}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-icons.jpg" />
                </Col>
                <Col xs={12} md={6}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-typography.jpg" />
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12} md={12}>
                  <Image src="/img/superdrug-mobile/brand-guidelines-signoff.jpg" />
                </Col>
              </Row>
            </Tab>
            <Tab label="POS Design">
              <Row className="show-grid">
                <Col xs={12} sm={12} md={6}>
                  <Image src="/img/superdrug-mobile/reka-6-column.jpg" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Image src="/img/superdrug-mobile/skates-6-column.jpg" />
                </Col>
              </Row>

              <Row className="show-grid side-padding ptb60">
                <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
                  <h2>POS Design</h2>
                  <p>
                    After the brand guidelines was put together I worked on the
                    POS that would go up in Superdrug stores. I worked closely
                    with Superdrug and their marketing team to come up with
                    templates that we felt would work well in store. The biggest
                    challenge in this part of the project was finding a way to
                    convey the product without having any imagery of the SIM.
                  </p>
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12} sm={12} md={4}>
                  <Image src="/img/superdrug-mobile/reka-6-column.jpg" />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <Image src="/img/superdrug-mobile/skates-6-column.jpg" />
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <Image src="/img/superdrug-mobile/skates-6-column.jpg" />
                </Col>
              </Row>
            </Tab>
            <Tab label="Packaging Design">
              <Row className="show-grid">
                <Col xs={12}>
                  <Image src="/img/superdrug-mobile/t-mailer-net.jpg" />
                </Col>
              </Row>
              <Row className="show-grid">
                <Col xs={12}>
                  <Image src="/img/superdrug-mobile/t-mailer-cover-net.jpg" />
                </Col>
              </Row>

              <Row className="show-grid">
                <Col xs={12} sm={12} md={6}>
                  <Image src="/img/superdrug-mobile/pouch-net.jpg" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Image src="/img/superdrug-mobile/leaflet-side1.jpg" />
                  <Image src="/img/superdrug-mobile/leaflet-side2.jpg" />
                </Col>
              </Row>


            </Tab>
            <Tab label="Animation">
              <Row className="show-grid ptb60">
                <Col xs={12}>
                  <video
                    width="100%"
                    height="100%"
                    src="/img/superdrug-mobile/2018SUPM2206_employee_draft_001.mp4"
                    controls
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>

    {/* <Tab.Container id="tabs-with-dropdown" defaultActiveKey="first">
      <Row className="clearfix">
        <Col sm={12} md={12}>
          <Nav bsStyle="pills">
            <NavItem eventKey="first">Brand Guidelines</NavItem>
            <NavItem eventKey="second">POS Design</NavItem>
            <NavItem eventKey="third">Packaging Design</NavItem>
            <NavItem eventKey="fourth">Animation</NavItem>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content animation>
            <Tab.Pane eventKey="first"></Tab.Pane>

            <Tab.Pane eventKey="second">
              
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container> */}

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default SuperdrugMobilePage;
